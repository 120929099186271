@import 'shared/style/index.scss';

.nl-create {
  padding: $spacing-md $spacing-2xl;
  border-radius: 4px;
  background-color: $color-blue;
  color: $color-white;
  border: none;
  font-size: $font-size-smaller;
  font-weight: $font-weight-medium;
  float: right;
  margin-bottom: $spacing-lg;
  cursor: pointer;

  body &:hover,
  body &:active {
    color: $color-white;
    background-color: rgba($color-blue, 75%);
  }
}

.newsletter-list {
  &-root {
    width: 100%;
    height: 100%;
    vertical-align: center;
    text-align: center;
  }
}