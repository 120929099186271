@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.home-row {
  &-actions {
    text-align: right;
    padding-right: $spacing-xl;
  }

  &-action:not(:last-child) {
    margin-right: $spacing-md;
  }

  &-icon {
    vertical-align: top;
    font-size: $font-size-big;

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-icon:not(&-icon-disabled):hover {
    color: $color-blue;
  }

  &-published-on {
    display: block;
    font-size: $font-size-micro;
    line-height: 22px;

    &-icon {
      margin-right: $spacing-2xs;
    }
  }

  &-first-cell {
    padding-left: $spacing-xl;
  }

  &-current {
    &-container {
      height: 20px;
      width: 20px;
      position: absolute;
      margin-left: -42px;
      border-radius: 50px;
      background-color: $color-orange;
    }

    &-icon {
      display: block;
      margin-left: 3px;
      margin-top: 3px;
      color: $color-white;
    }
  }
}

.icon-green {
  color: $color-green;
}