@import "shared/style/core/flexbox";
@import "shared/style/core/fonts";
@import "shared/style/core/variables";
@import "shared/style/core/z-index";

.header {
  @extend .flx-row;
  @extend .flx-center;
  @extend .flx-jst-start;

  width: 100%;
  height: 85px;
    z-index: $z-index-header;
    padding-left: $spacing-xl;
    background-color: $color-white;

  &__content {
    flex-basis: 80%;
      text-align: left;
    }
    
    &__inner {
      @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-between;
    }
    
    &-title {
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      line-height: 23px;
      text-transform: uppercase;
      color: $color-blue;

    &-dark {
      color: $color-link;
      }

        &-icon {
          margin-right: $spacing-lg;
          display: inline-block;
          vertical-align: middle;
          color: $color-blue;
        }

        &-text {
          display: inline-block;
          vertical-align: middle;
          text-transform: uppercase;
          color: $color-blue;
        }
        }

    &__section {
      display: block;
  
      &-label {
        display: block;
        text-transform: uppercase;
        line-height: 14px;
        font-size: $font-size-micro;
        font-weight: $font-weight-regular;
        color: $color-darker-grey;
      }

        &-value {
          display: block;
          font-size: $font-size-base;
          font-weight: $font-weight-medium;
          line-height: 23px;
          color: $color-black;
        }
        }

    &__user {
      @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-flex-end;

    flex-basis: 15%;
    text-align: left;
      color: $color-link;

    &-image {
      font-size: $font-size-bigger;
        margin-right: $spacing-md;
        height: 38px;
      }

        &-logout {
          background-color: inherit;
          border: inherit;
          font-size: 12px;
          height: 40px;
          border-radius: 5px;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          text-transform: uppercase;

      &:not([disabled]):hover,
      &:not([disabled]):active {
          cursor: pointer;
          background-color: rgba($color-blue, 38%);
        }
      }

        &-text {
          display: block;
          margin-right: $spacing-md;
        }

        &-name {
          display: block;
          font-size: $font-size-smaller;
          font-weight: $font-weight-medium;
          line-height: 16px;
        }

        &-role {
          display: block;
          font-size: $font-size-micro;
          line-height: 14px;
          font-style: italic;
        }
        }
                                                                                                                                                                                                                                                                }