@import 'shared/style/index';

.newsletter-detail {
  &-content {
    width: 80%;
    display: inline-block;
  }

  &-actions {
    width: 20%;
    display: inline-block;
    position: fixed;
    text-align: center;
  }

  &-btn {
    width: 60%;
    height: 45px;
    display: block;
    margin: auto auto;
    border-radius: 4px;
    margin-bottom: $spacing-lg;
    background-color: rgba($color-blueish-grey, 13%);
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    color: $color-blueish-grey;
    border: 1px solid rgba($color-blueish-grey, 40%);
    cursor: pointer;

    &:disabled {
      background-color: rgba($color-blueish-grey, 40%);
      cursor: no-drop;
    }

    &-ico {
      margin-right: $spacing-xs;
      font-size: $font-size-large;
      position: absolute;
      margin-left: 20px;
      display: block;
    }

    &-generate {
      color: $color-white;
      background-color: $color-blue;

      body &:not([disabled]):hover,
      body &:not([disabled]):active {
        cursor: pointer;
        color: $color-white;
        background-color: rgba($color-blue, 75%);
      }
    }

    &:hover:not(:disabled),
    &:active:not(:disabled) {
      background-color: rgba($color-blue, 13%);
      color: $color-blue;
      border: 1px solid rgba($color-blue, 65%);
    }
  }
}