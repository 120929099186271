.toggle {
  &-wrapper {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &-content {
    padding: 10px 0 0 0;
  }

  &-label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    width: 120px;
    height: 50px;
    background: #d21626;
    border-radius: 5px;
    transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);

    &::before,
    &::after {
      position: absolute;
      line-height: 50px;
      font-size: 14px;
      z-index: 2;
      transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    }

    &::before {
      content: 'NON';
      left: 20px;
      color: #d21626;
    }

    &::after {
      content: 'OUI';
      right: 20px;
      color: #fff;
    }
  }

  &-english {
    &::before {
      content: 'NO';
    }

    &::after {
      content: 'YES';
    }
  }

  &-span {
    display: inline-block;
    position: relative;
    // z-index: 1;
    background: #fff;
    width: 65px;
    height: 44px;
    border-radius: 3px;
    top: 3px;
    left: 3px;
    transition: all 200ms cubic-bezier(0.445, 0.05, 0.55, 0.95);
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  &-input {
    position: absolute;
    left: -99em;

    &:checked+.toggle-label {
      background: #66b317;
    }

    &:checked+.toggle-label::before {
      color: #fff;
    }

    &:checked+.toggle-label::after {
      color: #66b317;
    }

    &:checked+.toggle-label .toggle-span {
      width: 54px;
      -webkit-transform: translateX(60px);
      transform: translateX(60px);
      border-color: #fff;
    }
  }
}