@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.create-nl-modal {
  display: block;

  &-create {
    display: block;
    width: 229px;
    height: 45px;
    margin: $spacing-xl auto $spacing-2xl auto;
    background-color: $color-blue;
    border-radius: 4px;
    color: $color-white;
    font-size: $font-size-smaller;
    cursor: pointer;

    &:hover,
    &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }
  }
}