@import 'shared/style/index.scss';

.event-list {
  display: block;

  &-root {
    width: 100%;
    height: 100%;
    vertical-align: center;
    text-align: center;
  }
}
