@import 'reset';
@import 'variables';
@import 'fonts';
@import 'mixins';
@import './z-index';
@import 'icons';
@import 'flexbox';

body {
  font-family: $font-base;
  font-weight: $font-weight-regular;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}