@import 'shared/style/core/flexbox';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.header {
  &-inner {
    @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-between;
  }

  &-title {
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: 23px;
    text-transform: uppercase;
    color: $color-blue;

    &-dark {
      color: $color-link;
    }

    &-icon {
      margin-right: $spacing-lg;
      display: inline-block;
      vertical-align: middle;
      color: $color-blue;
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      color: $color-blue;
    }
  }

  &-section {
    display: block;

    &-label {
      display: block;
      text-transform: uppercase;
      line-height: 14px;
      font-size: $font-size-micro;
      font-weight: $font-weight-regular;
      color: $color-darker-grey;
    }

    &-value {
      display: block;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      line-height: 23px;
      color: $color-black;
    }
  }
}