@import 'shared/style/index.scss';

.publish-modal {
  display: block;

  &-mode-selection {
    margin-bottom: $spacing-xl;
  }

  &-pickers {
    @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-between;
  }

  &-picker {
    position: relative;
  }

  &-confirm {
    display: block;
    width: 229px;
    height: 45px;
    margin: $spacing-xl auto 0 auto;
    background-color: $color-blue;
    border-radius: 4px;
    color: $color-white;
    font-size: $font-size-smaller;
    cursor: pointer;

    &:hover,
    &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }
  }
}

.warningUnpublishedModifications {
  background: $color-yellow;
  padding: $spacing-md;
  display: flex;
}

.warningIcon {
  font-size: $font-size-big;
  color: $color-blueish-grey;
  margin-right: $spacing-md;
}

.loader {
  border: 4px solid $color-darker-grey;
  border-top: 4px solid $color-white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  position: absolute;
  bottom: 42px;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}