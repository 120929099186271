@import 'shared/style/index.scss';

.publish-modal {
  display: block;

  &-pickers {
    @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-between;
  }

  &-picker {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-label {
      text-align: left;
      color: $color-blueish-grey;
      display: block;
      font-size: $font-size-micro;
      line-height: 14px;
      margin-bottom: $spacing-xs;
      text-transform: uppercase;
    }
  }

  &-confirm {
    display: block;
    width: 229px;
    height: 45px;
    margin: 0 auto 0 auto;
    background-color: $color-blue;
    border-radius: 4px;
    color: $color-white;
    font-size: $font-size-smaller;
    cursor: pointer;

    &:hover,
    &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }
  }
}

.loader {
  border: 4px solid $color-darker-grey;
  border-top: 4px solid $color-white;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
  position: absolute;
  bottom: 42px;
  margin-left: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}