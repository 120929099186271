.faillites {
  &-root {
    width: 100%;
    margin-top: -6px;
  }

  &-item {
    width: 33%;
    display: inline-block;
    padding: 0 8px;
  }
}