@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.pagination {
  margin-top: $spacing-lg;

  &-btn {
    display: inline-block;
    border-radius: 4px;
    height: 45px;
    width: 45px;
    line-height: 45px;
    font-size: $font-size-smaller;
    border: none;
    background-color: transparent;
    color: $color-blueish-grey;
    transition: color 0.5s ease;
    cursor: pointer;

    &[hidden] {
      display: none;
    }

    &-active {
      background-color: $color-blue;
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }
  }
}