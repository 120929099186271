@import 'shared/style/core/flexbox';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';


.nav {
  $selected-border-width: 6px;

  width: 100%;
  margin-top: $spacing-xl;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;

  &-item {
    width: 100%;
    height: 60px;
    border-left: 6px solid transparent;
    font-size: 12px;
    line-height: 60px;
    text-align: left;
    transition: all 200ms linear;

    &-small {
      height: 30px;
      line-height: 30px;
      margin: 10px 0;
    }

    &-link {
      @extend .flx-row;
      @extend .flx-start;

      margin-left: 20px - $selected-border-width;
      width: 100%;
      height: 100%;

      &-icon {
        font-size: $font-size-larger;
        padding-top: 2px;
        display: block;
      }

      &-icon-container {
        display: block;
      }

      &-inner {
        text-transform: uppercase;
        padding-left: 12px;
      }
    }

    &-selected {
      border-left: $selected-border-width solid $color-blue;
      color: $color-blue;
      background-color: $color-grey;
    }
  }

  &-scrollMenu {
    &-selected {
      background-color: transparent;
      border-left: $selected-border-width solid $color-blue;
      color: $color-blue;
      font-weight: $font-weight-medium;
    }

    &-item {
      display: block;
      width: 100%;
      height: 100%;
      padding-left: $spacing-3xl;
    }
  }
}