@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.home-list {
  display: block;

  &-create-btn {
    padding: $spacing-md $spacing-2xl;
    border-radius: 4px;
    background-color: $color-blue;
    color: $color-white;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    border: none;
    cursor: pointer;

    &:hover,
    &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }

    &-container {
      text-align: right;
      margin-bottom: $spacing-lg;
    }
  }
}