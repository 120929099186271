@import 'shared/style/core/variables';

.twoColumnRow {
  display: flex;

  .column {
    flex: 1;
  }

  .column:nth-child(2n + 1) {
    margin-right: $spacing-xs;
  }
}
