.flx-row {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.flx-column {
  display: flex;
  /* For IE 10+ */
  flex-direction: column;
  flex-wrap: nowrap;
}

/* No code in selection */

.flx-start {
  align-items: flex-start;
}

.flx-center {
  align-items: center;
}

.flx-end {
  align-items: flex-end;
}

.flx-stretch {
  align-items: stretch;
}

.flx-jst-around {
  justify-content: space-around;
}

.flx-jst-between {
  justify-content: space-between;
}

.flx-jst-center {
  justify-content: center;
}

.flx-jst-start {
  justify-content: flex-start;
}

.flx-jst-flex-end {
  justify-content: flex-end;
}
