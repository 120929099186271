@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.tag-select {
  margin-bottom: $spacing-xl;
  width: 100%;

  &-title {
    color: $color-blueish-grey;
    display: block;
    font-size: $font-size-micro;
    line-height: 14px;
    margin-bottom: $spacing-xs;
    text-transform: uppercase;
  }

  &-value {
    background-color: $color-grey;
    border-radius: 2px;
    color: $color-blueish-grey;
    display: inline-block;
    font-size: $font-size-smaller;
    line-height: 1.4;
    margin-left: 5px;
    vertical-align: top;

    &-icon {
      line-height: 20px;
      vertical-align: text-top;
      margin-left: $spacing-xs;
      margin-right: $spacing-2xs;
    }

    &-label {
      border-bottom-right-radius: 2px;
      border-top-right-radius: 2px;
      cursor: default;
      padding: $spacing-3xs $spacing-2xs;

      &-inner {
        padding-top: $spacing-3xs;
        padding-left: $spacing-xs;
        display: inline-block;
      }
    }
  }
}