@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.publication-cell {
  &-pub {
    padding-right: $spacing-2xs;
  }

  &-published-on {
    display: block;
    font-size: $font-size-micro;
    line-height: 22px;

    &-icon {
      margin-right: $spacing-2xs;
    }
  }
}

.icon-green {
  color: $color-green;
}