@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.searchbar {
  height: 45px;
  margin-bottom: $spacing-lg;
  position: relative;

  &-input {
    padding-left: 50px;
    background-color: $color-white;
    font-weight: $font-weight-bold;
    border-radius: 4px 0 0 4px;
    border: 1px solid $color-light-grey;
    font-size: $font-size-smaller;
    color: $color-blueish-grey;
    width: 100%;
    height: 100%;

    &::placeholder {
      font-size: $font-size-smaller;
      color: $color-blueish-grey;
      font-weight: $font-weight-regular;
    }

    &-container {
      width: 80%;
      height: 100%;
      display: inline-block;
      position: relative;
    }
  }

  &-search {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    left: 16px;
    top: 10px;
    z-index: 1;

    &-ico {
      font-size: 17px;
      left: 0;
      color: $color-blueish-grey;
      top: 3px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &-clear {
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 10px;
    background-color: #f2f5f7;
    border: 1px solid #dde1e9;

    &:active {
      border: 2px solid #dde1e9;
    }

    &-ico {
      font-size: 17px;
      left: 0;
      color: $color-blueish-grey;
      top: 3px;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  &-btn {
    width: 20%;
    height: 100%;
    background-color: $color-blue;
    color: $color-white;
    font-size: 14px;
    border-radius: 0 4px 4px 0;
    border: none;
    padding: 0 0;
    cursor: pointer;
    transition: 0.2s ease-in background-color;

    body &:disabled {
      color: $color-white;
      background-color: rgba($color-dark-grey, 75%);
      cursor: no-drop;
    }

    body &:hover:not(:disabled),
    body &:active:not(:disabled) {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }
  }
}