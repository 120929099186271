@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.date-picker {
  height: 38px;
  width: 240px;
  margin-bottom: $spacing-xl;
  background-color: $color-white-grey;
  border-radius: 4px;
  border: 1px solid $color-med-grey;
  color: $color-black;
  font-size: $font-size-base;
  padding-left: $spacing-2xl;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.15s ease;

  &-required {
    color: $color-dark-red;
    vertical-align: super;
    margin-left: $spacing-3xs;
  }

  &-container {
    position: relative;
  }

  &:disabled {
    background-color: rgba(#dde1e9, 30%);
    cursor: default;
  }

  &:focus {
    background-color: white;
    border: 2px solid $color-dark-blue;
    box-shadow: inset 0 0 2px 0 rgba($color-real-black, 25%);
  }

  &-icon {
    left: 10px;
    bottom: 30px;
    position: absolute;
    font-size: $font-size-largest;
    color: $color-blueish-grey;
  }

  &-label {
    text-align: left;
    color: $color-blueish-grey;
    display: block;
    font-size: $font-size-micro;
    line-height: 14px;
    margin-bottom: $spacing-xs;
    text-transform: uppercase;
  }
}