$font-IBM: 'IBMPlex', 'Helvetica Neue', sans-serif;
$font-weight-bold: 700;
$font-weight-medium: 500;
$font-size-micro: 0.75rem;
$font-size-smaller: 0.875rem;
$font-size-large: 1.215rem;

$color-blueish-lightgrey: #1c99f4;
$color-blueish-grey: #697f90;
$color-real-white: #fff;
$color-white: #ebe9e9;
$color-coral: #ed1b2e;

$spacing-xl: 32px;
$spacing-lg: 24px;
$spacing-md: 16px;
$spacing-2xs: 4px;
$spacing-2xl: 40px;

body {
  font-family: $font-IBM;
  font-weight: 400;
  background-color: #f1f4f6;
}

.login {
  &-wrapper {
    width: 455px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    left: 0;
  }

  &-loading {
    width: 75px;
    height: 75px;
    margin: auto auto;
    border: 5px solid $color-blueish-lightgrey;
    border-top: 4px solid $color-white;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  &-logo {
    font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1;

    &-wrapper {
      width: auto;
      text-align: center;
      margin: 0 $spacing-xl $spacing-xl;
      font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
      font-size: 32px;
      line-height: 1;
    }

    &-sub-logo {
      width: 100%;
      font-size: 20px;
      color: $color-blueish-lightgrey;
      font-weight: $font-weight-bold;
    }
  }

  &-box {
    margin: auto 0;
    background-color: $color-real-white;
    border: 1px solid $color-white;
    box-sizing: border-box;
    border-radius: 4px;
    padding: $spacing-xl $spacing-xl $spacing-2xl $spacing-xl;
  }

  &-title {
    color: $color-blueish-grey;
    text-transform: uppercase;
    font-weight: $font-weight-medium;
    font-size: $font-size-large;
    text-align: center;
  }

  &-label {
    color: $color-blueish-grey;
    text-transform: uppercase;
    font-size: $font-size-micro;
    margin-bottom: $spacing-2xs;
  }

  &-input {
    display: block;
    width: 100%;
    padding: $spacing-md $spacing-md;
    margin-bottom: $spacing-lg;
    background: $color-real-white;
    border: 1px solid $color-white;
    box-sizing: border-box;
    border-radius: 2px;
    color: $color-blueish-grey;
    font-size: $font-size-smaller;
  }

  &-submit {
    display: block;
    width: 230px;
    margin: auto auto;
    padding: $spacing-md 0;
    background-color: $color-blueish-lightgrey;
    color: $color-real-white;
    border-radius: 4px;
    font-size: $font-size-smaller;
    cursor: pointer;
    text-align: center;
    border: none;

    &-loading {
      width: 48px;
      height: 48px;
      margin: auto auto;
      border: 5px solid $color-blueish-lightgrey;
      border-top: 4px solid $color-white;
      border-radius: 50%;
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  &-forgotten-pwd {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: $spacing-md;
    color: $color-blueish-grey;
    font-size: $font-size-micro;
  }

  &-error {
    margin: $spacing-md 0;
    background-color: rgba($color-coral, 15%);
    color: rgba($color-coral, 75%);
    font-family: Roboto, sans-serif;
    border-radius: 2px;
    font-size: 14px;
    text-align: center;
    line-height: 40px;
    width: 100%;
    height: 40px;
    position: relative;

    &-icon {
      &-container {
        position: relative;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: rgba($color-coral, 35%);
      }

      &-placer {
        position: absolute;
        top: 8px;
        left: 16px;
      }

      position: absolute;
      height: 1px;
      top: -5px;
      right: 3px;
      font-size: 17px;
    }
  }
}