@import 'shared/style/index.scss';

.newsletter-row {
  &-title {
    padding-left: 32px;
  }

  &-actions {
    text-align: right;
    padding-right: $spacing-xl;
  }

  &-action:not(:last-child) {
    margin-right: $spacing-md;
  }

  &-published-on {
    display: block;
    font-size: $font-size-micro;
    line-height: 22px;

    &-icon {
      margin-right: $spacing-2xs;
    }
  }

  &-icon {
    vertical-align: top;
    font-size: $font-size-big;
  }

  &-icon:hover {
    color: $color-blue;
  }
}

.icon-green {
  color: $color-green;
}