@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.radio-input {
  margin: $spacing-xs 0 $spacing-xl 0;

  &_description {
    display: block;
    text-align: left;
    margin-bottom: $spacing-xs;
    color: $color-blueish-grey;
    font-size: $font-size-micro;
    line-height: $font-size-micro;
    text-transform: uppercase;
  }

  &_label {
    display: inline-block;
    min-width: 62px;
    box-sizing: border-box;
    padding: $spacing-xs;
    text-align: center;
    text-transform: uppercase;
    background: #f2f5f7;
    cursor: pointer;
    font-size: $font-size-micro;
    border: 1px solid #dde1e9;

    &:first-of-type {
      border-radius: 2px 0 0 2px;
      border-right: 0;
    }

    &:last-of-type {
      border-radius: 0 2px 2px 0;
      border-left: 0;
    }

    &-checked {
      color: $color-white;
      background-color: $color-blue;
      border-color: $color-blue;
    }
  }

  &_item {
    display: none;
  }
}