/*
colors
 */

$color-grey: #ecf0f3;

$color-bg: #e5e5e5;

$color-white: #fff;

$color-real-black: #000;

$color-blue: #1c99f4;

$color-dark-blue: #2684ff;

$color-light-grey: #e6eaf2;

$color-blueish-lightgrey: #f1f4f6;

$color-dark-grey: #7e92a2;

$color-darker-grey: #adadb4;

$color-white-grey: #fafafa;

$color-med-grey: #ccc;

$color-blueish-grey: #697f90;

$color-link: #4b4b4b;

$color-black: #2d2f31;

$color-green: #219653;

$color-dark-red: #c50505;

$color-light-orange: #f3a560;
$color-orange: #eb5757;

$color-reddish-grey: #f1e9e9;

$color-yellow: #fec10d;

$color-coral: #ed1b2e;
/*
 spacings
  */
$spacing-3xs: 2px;

$spacing-2xs: 4px;

$spacing-xs: 8px;

$spacing-md: 16px;

$spacing-lg: 24px;

$spacing-xl: 32px;

$spacing-2xl: 40px;

$spacing-3xl: 48px;

$spacing-4xl: 56px;