@import url('https://fonts.googleapis.com/css?family=Roboto:200,300,400,500,700');

$font-base: 'Roboto', sans-serif;

$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

$font-size-nano: 0.625rem; // 10px
$font-size-micro: 0.75rem; // 12px
$font-size-smaller: 0.875rem; // 14px
$font-size-small: 0.9375rem; // 15px
$font-size-base: 1rem; // 16px
$font-size-large: 1.215rem; // 18px
$font-size-larger: 1.25rem; // 20px
$font-size-largest: 1.375rem; // 22px
$font-size-big: 1.5rem; // 24px
$font-size-bigger: 1.875rem; // 30px;