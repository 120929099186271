@import 'shared/style/core/flexbox';

.container {
  @extend .flx-row;
  @extend .flx-jst-between;
  align-items: flex-start;
}

.action {
  &-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }

  &-item {
    flex-grow: 0.41;
  }
}

.titleOut {
  width: 320px;
}
