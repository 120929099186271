@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.data-table {
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
  border-radius: 30px;
  border-spacing: 0;
  position: relative;

  .cursorInitial {
    cursor: initial;
  }

  &-header {
    &-sort-icon {
      vertical-align: sub;
      margin-left: 2px;
    }

    &-active {
      color: white;

      &:hover {
        color: white;
      }
    }

    &::after {
      line-height: $spacing-md;
      content: '.';
      color: white;
      display: block;
    }

    &-last {
      text-align: right;
    }

    &-row {
      color: $color-darker-grey;
      background-color: $color-black;
      height: 60px;

      & th:first-child {
        padding-left: $spacing-xl;
      }

      & th:last-child {
        padding-right: $spacing-xl;
      }
    }
  }

  // No border radius on tables
  & td {
    border-bottom: 1px solid $color-light-grey;
  }

  & td:first-child {
    border-left: 1px solid $color-light-grey;
  }

  & td:last-child {
    border-right: 1px solid $color-light-grey;
  }

  & tr:first-child td {
    border-top: 1px solid $color-light-grey;
  }

  & tbody tr:first-child td:first-child {
    border-radius: 4px 0 0 0;
  }

  & tbody tr:first-child td:last-child {
    border-radius: 0 4px 0 0;
  }

  & tbody tr:last-child td:first-child {
    border-radius: 0 0 0 4px;
  }

  & tbody tr:last-child td:last-child {
    border-radius: 0 0 4px 0;
  }

  & thead th:last-child {
    border-radius: 0 4px 4px 0;
  }

  & thead th:first-child {
    border-radius: 4px 0 0 4px;
  }

  &-container {
    display: block;
  }

  &-row {
    &:hover {
      background-color: $color-blueish-lightgrey;
      cursor: pointer;
    }

    line-height: 16px;
    background-color: $color-white;
    color: $color-blueish-grey;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    vertical-align: middle;
    border: 1px solid $color-light-grey;
    height: 75px;
    padding: 0 $spacing-xl;
  }

  &-tbody-loading {
    filter: blur(2px) brightness(95%);
  }

  &-loading {
    position: absolute;
    width: 75px;
    height: 75px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto auto;
    border: 5px solid $color-darker-grey;
    border-top: 4px solid $color-white;
    border-radius: 50%;
    z-index: 1;
    animation: spin 2s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }
}