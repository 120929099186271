@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.copy-field {
  &-input {
    background-color: $color-white;
    border: 1px solid $color-darker-grey;
    color: $color-blueish-grey;
    font-size: $font-size-smaller;
    width: 80%;
    height: 60px;
    padding-left: 1em;
    border-radius: 4px 0 0 4px;
    vertical-align: top;
  }

  &-btn {
    background-color: $color-blue;
    border: 1px solid $color-blue;
    color: $color-white;
    font-size: $font-size-smaller;
    width: 20%;
    height: 60px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;

    body &:hover,
    body &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }
  }
}