@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.select {
  width: 100%;
  margin-bottom: $spacing-xl;

  &-required {
    color: $color-dark-red;
    vertical-align: super;
    margin-left: $spacing-3xs;
  }

  &-label {
    color: $color-blueish-grey;
    display: block;
    font-size: $font-size-micro;
    line-height: 14px;
    margin-bottom: $spacing-xs;
    text-transform: uppercase;
  }
}

.select-invalid>div {
  border-color: $color-dark-red;
  background-color: $color-reddish-grey;
}