@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.action-cell {
  &-root {
    text-align: right;
    padding-right: $spacing-xl;
  }

  &-action:not(:last-child) {
    margin-right: $spacing-md;
  }

  &-icon {
    vertical-align: top;
    font-size: $font-size-big;

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-icon:not(&-icon-disabled):hover {
    color: $color-blue;
  }
}