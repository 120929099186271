@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';
@import 'shared/style/core/z-index';


.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(105, 127, 144, 0.4);
  z-index: $z-index-modal-overlay;
}

.modal {
  position: relative;
  top: 50%;
  width: 580px;
  margin: auto auto;
  background-color: $color-white;
  border: 1px solid $color-light-grey;
  border-radius: 4px;
  transform: translateY(-50%);
  z-index: $z-index-modal;

  &-header {
      padding: $spacing-xl $spacing-xl;
      text-align: center;
      background-color: $color-blueish-lightgrey;

    .close-icon {
        position: absolute;
        right: 8px;
        top: 6px;
        cursor: pointer;
      }

        &-title {
          &-label {
            display: block;
            font-size: $font-size-smaller;
            color: $color-blueish-grey;
            line-height: 16px;
          }

            &-value {
              display: block;
              margin-top: $spacing-lg;
              font-weight: $font-weight-medium;
              text-transform: uppercase;
              font-size: $font-size-base;
              color: $color-blueish-grey;
              line-height: 19px;
            }
            }
            }

    &-content {
      padding: $spacing-xl $spacing-xl;
    }
    }

.body-open-modal {
  overflow: hidden;
}