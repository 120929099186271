.workshop {
  &-content {
    display: inline-block;
    width: 100%;
  }

  &-sections {
    display: inline-block;
    width: 80%;
  }
}