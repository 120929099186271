@import 'shared/style/core/variables';

.root {
  background-color: $color-bg;
  width: 100vw;
  height: 100vh;
}

.main {
  background-color: $color-blueish-lightgrey;
  min-height: 100%;
}