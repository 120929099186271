@import 'shared/style/core/flexbox';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

$header-height: 85px;

.preview {
  position: fixed;
  width: 100%;
  height: 100%;

  &-header {
    height: $header-height;
    background-color: $color-black;

    @extend .flx-row;
      @extend .flx-center;
      @extend .flx-jst-around;
  }

  &-prev-btn {
    width: 130px;
    height: 34px;
    border: 1px solid $color-white;
    background-color: $color-black;
    font-size: $font-size-micro;
    border-radius: 62px;
    color: $color-white;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &-container {
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }

    &-text {
      vertical-align: text-bottom;
      margin-left: $spacing-xs;
      height: 17px;
      display: inline-block;
    }

    &_icon {
      vertical-align: inherit;
    }
  }

  &__inner {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  &__section {
    display: block;

    &-label {
      display: block;
      text-transform: uppercase;
      line-height: 14px;
      font-size: $font-size-micro;
      font-weight: $font-weight-regular;
      color: $color-darker-grey;
    }

    &-value {
      display: block;
      font-size: $font-size-base;
      font-weight: $font-weight-medium;
      line-height: 23px;
      color: $color-white;
    }
  }

  &-buttons {
    display: inline-block;
    text-align: right;
  }

  &-edit-btn {
    position: relative;
    vertical-align: top;
    width: 45px;
    height: 45px;
    border-radius: 4px;
    background-color: $color-light-grey;
    color: $color-link;
    border: none;
    cursor: pointer;

    &-icon {
      position: absolute;
      font-size: 1.215rem;
      left: 13px;
      bottom: 8px;
    }
  }

  &-edit-wide-btn {
    position: relative;
    margin: auto auto;
    width: 140px;
    height: 45px;
    border-radius: 4px;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    line-height: 20px;
    background-color: $color-light-grey;
    border: 1px solid rgba($color-light-grey, 40%);
    margin-left: $spacing-xs;
    cursor: pointer;

    &:disabled {
      background-color: rgba($color-light-grey, 40%);
    }

    &-icon {
      position: absolute;
      font-size: $font-size-large;
      left: 16px;
    }
  }

  &-publish-btn {
    position: relative;
    margin: auto auto;
    width: 140px;
    height: 45px;
    border-radius: 4px;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    line-height: 20px;
    color: $color-white;
    background-color: $color-blue;
    border: 1px solid rgba($color-blueish-grey, 40%);
    margin-left: $spacing-xs;
    cursor: pointer;

    &:disabled {
      background-color: rgba($color-blueish-grey, 40%);
    }

    &-icon {
      position: absolute;
      font-size: $font-size-large;
      left: 16px;
    }
  }

  &-iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;

    &-loading {
      position: absolute;
      width: 75px;
      height: 75px;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto auto;
      border: 5px solid $color-darker-grey;
      border-top: 4px solid $color-white;
      border-radius: 50%;
      z-index: 1;
      animation: spin 2s linear infinite;

      @keyframes spin {
        0% {
          transform: rotate(0deg);
        }

        100% {
          transform: rotate(360deg);
        }
      }
    }

    &-container {
      margin-top: $header-height;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        box-shadow: inset 0 11px 8px -10px black, inset 0 -11px 8px -10px black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        pointer-events: none;
      }
    }
  }
}
