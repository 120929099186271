@import 'shared/style/core/z-index';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';
@import 'shared/style/core/flexbox';


.aside {

  @extend .flx-column;
    @extend .flx-center;
    @extend .flx-column;
  
    .flx-center {
      align-items: center;
    }
  
    &[hidden] {
      display: none;
    }

    position: fixed;
    top: 0;
    width: 240px;
    height: 100vh;
    z-index: $z-index-side-menu;
    padding-top: 100px;
    background-color: $color-white;
    box-shadow: 0 1px 24px rgba(190, 201, 208, 0.53);

    &-prev-btn {
      width: 167px;
      height: 33px;
      border: 1px solid $color-dark-grey;
      font-size: $font-size-micro;
      border-radius: 62px;
      color: $color-dark-grey;
      cursor: pointer;
      vertical-align: middle;
      text-align: center;
      padding-top: 7px;
      position: absolute;
      top: 30px;

    &:hover {
        color: $color-blue;
        border: 1px solid $color-blue;
      }

        &-text {
          vertical-align: text-bottom;
          margin-left: $spacing-xs;
          height: 17px;
          display: inline-block;
        }

        &_icon {
          vertical-align: inherit;
        }

        &-hidden {
          visibility: hidden;
        }
        }
        }