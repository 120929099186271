@import 'shared/style/core/flexbox';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';


.file-input-wrapper {
  @extend .flx-row;

  height: 40px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
  border-style: solid;
  border-color: $color-med-grey;
  position: relative;
}

.file-input-name {
  height: 38px;
  width: 100%;
  margin-bottom: $spacing-xl;
  background-color: $color-white-grey;
  border: none;
  color: $color-black;
  font-size: $font-size-base;
  padding-left: $spacing-md;
  cursor: pointer;
  transition: all 0.15s ease;
}

.file-input-wrapper>input[type='file'] {
  display: none;
}

.file-input-btn {
  position: absolute;
  right: 0;
  width: 132px;
  height: 38px;
  background-color: rgba($color-blueish-grey, 13%);
  font-size: $font-size-nano;
  font-weight: $font-weight-medium;
  color: $color-blueish-grey;
  border-radius: 0 2px 2px 0;
  border-width: 0 1px 0 1px;
  border-style: solid;
  border-color: rgba($color-blueish-grey, 40%);
  box-sizing: border-box;
  cursor: pointer;
}

.file-input-reset-btn {
  box-sizing: border-box;
  position: absolute;
  top: 6px;
  right: 134px;
  width: 26px;
  height: 26px;
  background: rgba($color-blueish-grey, 13%);
    border: 1px solid rgba($color-blueish-grey, 40%);
  border-radius: 50%;
  color: $color-black;
}

.file-input-required {
  color: $color-dark-red;
  vertical-align: super;
  margin-left: $spacing-3xs;
}

.file-input-label {
  text-align: left;
  color: $color-blueish-grey;
  display: block;
  font-size: $font-size-micro;
  line-height: 14px;
  margin-bottom: $spacing-xs;
  text-transform: uppercase;
}

.file-input-legend {
  font-size: $font-size-nano;
  color: $color-blueish-grey;
}