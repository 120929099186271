@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.section {
  margin-bottom: $spacing-4xl;

  &-title {
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    margin-bottom: $spacing-md;
    color: $color-blueish-grey;
    text-transform: uppercase;
    margin-left: $spacing-2xs;
  }

  &-content {
    border-radius: 4px;
    background-color: $color-white;
    border: 1px solid $color-light-grey;
    padding: $spacing-md $spacing-lg 0 $spacing-lg;
  }
}