.event {
  &-content {
    display: inline-block;
    width: 100%;
  }

  &-sections {
    display: inline-block;
    width: 80%;
  }
}

.woodwing-error {
  color: red;
  text-align: center;
  padding-top: 24px;
}
