@import 'shared/style/core/flexbox';
@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.list-form {
  @extend .flx-column;

  &-item {
    @extend .flx-row;
    align-items: flex-end;
    flex: 1;

    &-column {
      @extend .flx-column;
    }
  }

  &-add-button {
    height: 35px;
    margin-bottom: 20px;
    background: rgba($color-blueish-grey, 13%);
      border: 1px solid rgba($color-blueish-grey, 40%);
    border-radius: 4px;
    font-size: $font-size-base;
    color: $color-blueish-grey;
    cursor: pointer;

    &:not([disabled]):hover,
    &:not([disabled]):active {
      background-color: rgba($color-blue, 13%);
      color: $color-blue;
      border: 1px solid rgba($color-blue, 65%);
    }
  }

  &-remove-button {
    width: 50px;
    height: 38px;
    margin: 0 0 32px 10px;
    background: #f2f5f7;
    border: 1px solid #dde1e9;
    border-radius: 2px;
    font-size: $font-size-smaller;
    color: $color-blueish-grey;
  }
}