@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.dossier-row {
  &-actions {
    text-align: right;
    padding-right: $spacing-xl;
  }

  &-action:not(:last-child) {
    margin-right: $spacing-md;
  }

  &-icon {
    vertical-align: top;
    font-size: $font-size-big;

    &-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &-icon:not(&-icon-disabled):hover {
    color: $color-blue;
  }

  &-published-on {
    display: block;
    font-size: $font-size-micro;
    line-height: 22px;

    &-icon {
      margin-right: $spacing-2xs;
    }
  }

  &-image-cell {
    width: 100px;
    height: 70px;
  }

  &-first-cell {
    padding-left: $spacing-xl;
  }
}

.icon-green {
  color: $color-green;
}
