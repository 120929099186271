@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.output-text {
  width: 100%;
  margin-bottom: $spacing-xl;
  color: $color-black;
  font-size: $font-size-base;

  &-label {
    text-align: left;
    color: $color-blueish-grey;
    display: block;
    font-size: $font-size-micro;
    line-height: 14px;
    margin-bottom: $spacing-xs;
    text-transform: uppercase;
  }
}