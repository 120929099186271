.checkbox-input {
  &-checkbox {
    margin: 5px;
    background: rgb(204, 204, 204);

    &-checked {
      margin: 5px;
      background: #1c99f4;
    }
  }

  &-label {
    color: #2d2f31;
  }
}