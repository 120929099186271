@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.time-picker {
  height: 38px;
  width: 240px;
  margin-bottom: $spacing-xl;
  padding-left: $spacing-2xl;
  background-color: $color-white-grey;
  border-radius: 4px;
  border: 1px solid $color-med-grey;
  color: $color-black;
  font-size: $font-size-base;
  text-transform: uppercase;
  font-family: $font-base;
  transition: all 0.15s ease;
  cursor: pointer;

  &-container {
    position: relative;
  }

  &-required {
    color: $color-dark-red;
    vertical-align: super;
    margin-left: $spacing-3xs;
  }

  &:disabled {
    background-color: rgba(#dde1e9, 30%);
    cursor: default;
  }

  &:focus {
    background-color: white;
    border: 2px solid $color-dark-blue;
    box-shadow: inset 0 0 2px 0 rgba($color-real-black, 25%);
  }

  &-inline {
    width: 3em;
    border: none;
    font-family: $font-base;
    font-size: $font-size-base;
    font-weight: $font-weight-medium;
    line-height: 23px;
    color: $color-black;
    cursor: pointer;

    &-icon {
      vertical-align: text-top;
      cursor: pointer;
    }
  }

  &-icon {
    position: absolute;
    left: 10px;
    bottom: 31px;
    font-size: $font-size-largest;
    color: $color-blueish-grey;
  }

  &-label {
    display: block;
    margin-bottom: $spacing-xs;
    text-align: left;
    color: $color-blueish-grey;
    font-size: $font-size-micro;
    line-height: 14px;
    text-transform: uppercase;
  }
}