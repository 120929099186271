@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';


.logo {
  font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
  font-size: 32px;
  line-height: 1;

  &-wrapper {
    width: auto;
    text-align: center;
    margin: 0 $spacing-xl $spacing-xl;
    font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
    font-size: 32px;
    line-height: 1;
  }

  &-sub-logo {
    width: 100%;
    font-size: 20px;
    color: $color-blue;
    font-weight: $font-weight-bold;
  }
}