@charset "UTF-8";

@font-face {
  font-family: 'backoffice';
  src: url('fonts/backoffice.eot');
  src: url('fonts/backoffice.eot?#iefix') format('embedded-opentype'), url('fonts/backoffice.woff') format('woff'),
    url('fonts/backoffice.ttf') format('truetype'), url('fonts/backoffice.svg#backoffice') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'backoffice' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'backoffice' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-read:before {
  content: '\48';
}
.icon-articles:before {
  content: '\61';
}
.icon-tag:before {
  content: '\64';
}
.icon-eye:before {
  content: '\65';
}
.icon-trash:before {
  content: '\66';
}
.icon-arrow:before {
  content: '\68';
}
.icon-reload:before {
  content: '\62';
}
.icon-delete:before {
  content: '\6b';
}
.icon-check-circle:before {
  content: '\6d';
}
.icon-sort:before {
  content: '\70';
}
.icon-calendar:before {
  content: '\6f';
}
.icon-paper-plane:before {
  content: '\71';
}
.icon-edit:before {
  content: '\72';
}
.icon-pencil:before {
  content: '\67';
}
.icon-woodwing:before {
  content: '\6c';
}
.icon-user:before {
  content: '\6a';
}
.icon-homepage-club:before {
  content: '\45';
}
.icon-profiles:before {
  content: '\46';
}
.icon-dossier:before {
  content: '\47';
}
.icon-clock:before {
  content: '\6e';
}
.icon-homepage:before {
  content: '\73';
}
.icon-pin:before {
  content: '\63';
}
.icon-next:before {
  content: '\75';
}
.icon-prev:before {
  content: '\74';
}
.icon-event:before {
  content: '\49';
}
.icon-workshop:before {
  content: '\43';
}
.icon-email:before {
  content: '\69';
}
.icon-first:before {
  content: '\76';
}
.icon-last:before {
  content: '\77';
}
.icon-search:before {
  content: '\79';
}
.icon-link:before {
  content: '\7a';
}
.icon-sort-asc:before {
  content: '\41';
}
.icon-sort-desc:before {
  content: '\42';
}
