@import 'shared/style/core/flexbox';
@import 'shared/style/core/variables';

.dnd-list {
  width: 100%;
  @extend .flx-center;

  &-item {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }

  &-action {
    margin: 0 0 5px 10px;
    width: 25px;
    height: 25px;
  }
}
