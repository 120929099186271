@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

.home-detail {
  &-content {
    width: 80%;
    display: inline-block;
  }

  &-general {
    &>div {
      margin-right: 50px;
      display: inline-block;
    }
  }

  &-buttons {
    width: 20%;
    display: inline-block;
    position: fixed;
    text-align: center;
  }

  &-button {
    width: 200px;
    height: 45px;
    display: block;
    margin: auto auto;
    border-radius: 4px;
    margin-bottom: $spacing-lg;
    background-color: rgba($color-blueish-grey, 13%);
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    color: $color-blueish-grey;
    border: 1px solid rgba($color-blueish-grey, 40%);

    &:disabled {
      background-color: rgba($color-blueish-grey, 40%);
    }

    &-validate {
      color: $color-white;
      background-color: $color-blue;

      body &:not([disabled]):hover,
      body &:not([disabled]):active {
        cursor: pointer;
        color: $color-white;
        background-color: rgba($color-blue, 75%);
      }
    }

    &-ico {
      margin-right: $spacing-xs;
      font-size: $font-size-large;
      position: absolute;
      margin-left: 20px;
      display: block;
    }

    &:not([disabled]):hover,
    &:not([disabled]):active {
      background-color: rgba($color-blue, 13%);
      color: $color-blue;
      border: 1px solid rgba($color-blue, 65%);
    }
  }
}