@import 'shared/style/core/fonts';
@import 'shared/style/core/variables';

$color-blueish-lightgrey: #1c99f4;
$color-blueish-grey: #697f90;
$color-real-white: #fff;
$color-white: #ebe9e9;
$color-coral: #ed1b2e;


.reset {
    &-wrapper {
        width: 455px;
        margin: 0 auto;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        left: 0;
    }

    &-loading {
        width: 75px;
        height: 75px;
        margin: auto auto;
        border: 5px solid $color-blueish-lightgrey;
        border-top: 4px solid $color-white;
        border-radius: 50%;
        animation: spin 2s linear infinite;

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }

            100% {
                transform: rotate(360deg);
            }
        }
    }

    &-logo {
        font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
        font-size: 32px;
        line-height: 1;

        &-wrapper {
            width: auto;
            text-align: center;
            margin: 0 $spacing-xl $spacing-xl;
            font-family: 'DejaVu Sans', Arial, Helvetica, sans-serif;
            font-size: 32px;
            line-height: 1;
        }

        &-sub-logo {
            width: 100%;
            font-size: 20px;
            color: $color-blueish-lightgrey;
            font-weight: $font-weight-bold;
            text-align: center;
        }
    }

    &-box {
        margin: auto 0;
        background-color: $color-real-white;
        border: 1px solid $color-white;
        box-sizing: border-box;
        border-radius: 4px;
        padding: $spacing-xl $spacing-xl $spacing-2xl $spacing-xl;
    }

    &-title {
        color: $color-blueish-grey;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        font-size: $font-size-large;
        text-align: center;
    }

    &-label {
        color: $color-blueish-grey;
        text-transform: uppercase;
        font-size: $font-size-micro;
        margin-bottom: $spacing-2xs;
    }

    &-input {
        display: block;
        width: 100%;
        padding: $spacing-md $spacing-md;
        margin-bottom: $spacing-lg;
        background: $color-real-white;
        border: 1px solid $color-white;
        box-sizing: border-box;
        border-radius: 2px;
        color: $color-blueish-grey;
        font-size: $font-size-smaller;
    }

    &-submit {
        display: block;
        width: 230px;
        margin: auto auto;
        padding: $spacing-md 0;
        background-color: $color-blueish-lightgrey;
        color: $color-real-white;
        border-radius: 4px;
        font-size: $font-size-smaller;
        cursor: pointer;
        text-align: center;
        border: none;

        &-loading {
            width: 48px;
            height: 48px;
            margin: auto auto;
            border: 5px solid $color-blueish-lightgrey;
            border-top: 4px solid $color-white;
            border-radius: 50%;
            animation: spin 2s linear infinite;

            @keyframes spin {
                0% {
                    transform: rotate(0deg);
                }

                100% {
                    transform: rotate(360deg);
                }
            }
        }
    }

    &-forgotten-pwd {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: $spacing-md;
        color: $color-blueish-grey;
        font-size: $font-size-micro;
    }

    &-back {
        text-align: center;
        margin-top: 20px;
    }

    &-back>a {
        color: $color-blueish-lightgrey;
        cursor: pointer;
    }

    &-show {
        margin-left: 5px;
        color: $color-blueish-grey;
        font-size: $font-size-smaller;
        text-align: end;
        cursor: pointer;
    }

    &-show>label {
        cursor: pointer;
    }

    &-error {
        margin: $spacing-md 0;
        background-color: fade($color-coral, 15%);
        color: fade($color-coral, 75%);
        font-family: Roboto, sans-serif;
        border-radius: 2px;
        font-size: 14px;
        text-align: left;
        width: 100%;
        position: relative;
        padding: $spacing-xs;
    }

    &-code {
        text-align: center;
        font-size: larger;
    }
}

.progress-bar {
    width: 100%;
    height: 5px;
    background-color: #ebebeb;
    position: relative;
    border: 1px solid #ebebeb;
    border-radius: 12px;
    top: -20px;
}

.password-strength-bar {
    height: 100%;
    transition: width 0.3s;
}

.strength-1 {
    background-color: rgba(255, 0, 0, 0.765);
    border: 1px solid rgba(255, 0, 0, 0.765);
    border-radius: 12px;
}

.strength-2 {
    border: 1px solid rgba(255, 166, 0, 0.648);
    border-radius: 12px;
    background-color: rgba(255, 166, 0, 0.648);
}

.strength-3 {
    background-color: rgba(1, 187, 129, 0.765);
    border: 1px solid rgba(1, 187, 129, 0.765);
    border-radius: 12px;
}