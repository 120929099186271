@import 'shared/style/index.scss';

.workshop-list {
  display: block;

  &-root {
    width: 100%;
    height: 100%;
    vertical-align: center;
    text-align: center;
  }

  &-create-btn {
    padding: $spacing-md $spacing-2xl;
    border-radius: 4px;
    background-color: $color-blue;
    color: $color-white;
    font-size: $font-size-smaller;
    font-weight: $font-weight-medium;
    cursor: pointer;

    body &:hover,
    body &:active {
      color: $color-white;
      background-color: rgba($color-blue, 75%);
    }

    &-container {
      text-align: right;
      margin-bottom: $spacing-lg;
    }
  }
}